/* eslint-disable no-undef */
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ProfilBarShow from "Components/ProfilBar/ProfilBarShow/ProfilBarShow";
import { fetchGet } from "Services/api";
import crown from "assets/crown.png";
import { Divider } from "primereact/divider";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../Services/functions";
import "./ProfilShow.scss";
import axios from "axios";

/**
 * Permet d'afficher la page de détails du profil d'une personne sélectionnée dans l'annuaire
 * Un utilisateur peut aussi avoir un aperçu de cette page pour son profil via "aperçu du profil" dans /profil
 * Accessible pour tous les utilisateurs
 */
const ProfilShow = (props) => {
  const { id } = useParams();
  const [userResume, setUserResume] = useState({ loaded: false, data: null });
  const [userData, setUserData] = useState({ loaded: false, data: null });

  const retrieveUserInformations = (userId) => {
    if (!userId && typeof userId !== "string") return;

    const data = fetchGet(`/resumes/cvteque`, "api", {
      "identifier[]": userId,
    });
    data
      .then((res) => {
        if (
          res.data.data.length === 0 ||
          !res.data.data[0] ||
          !res.data.data[0].id
        ) {
          displayErrorAndReturn(
            "Impossible de récupérer les informations de l'utilisateur, retour à la page précédente..."
          );
          return;
        }
        setUserResume({ loaded: true, data: res.data.data[0] });
      })
      .catch((error) => {
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: error.message,
          life: 3000,
        });
      });
  };

  const retrieveUserResume = (userId) => {
    if (!userId && typeof userId !== "string") return;

    const data = fetchGet(`/profile_show/${userId}`);
    data
      .then((res) => {
        setUserData({ loaded: true, data: res.data });
      })
      .catch((error) => {
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: error.message,
          life: 3000,
        });
      });
  };

  /**
   * Affiche une erreur et retourne à la page précédente
   *
   * Un délai de 3 secondes est ajouté pour laisser le temps à l'utilisateur de lire le message
   */
  const displayErrorAndReturn = async (message) => {
    props.auth.toast.current.show({
      severity: "error",
      summary: "Erreur",
      detail: message,
      life: 3000,
    });
    await new Promise((resolve) => setTimeout(resolve, 3000));
    window.history.back();
  };

  /**
   * Récupère le CV téléchargeable de l'utilisateur via l'URL
   * fourni dans {@link userResume}
   * - Si l'URL est fourni, une requête GET est effectuée pour récupérer le CV
   * @returns {Promise<Blob|null>} - Une promesse qui résout le contenu du fichier téléchargeable
   */
  const retrieveDownloadableResume = async () => {
    let url = userResume.data?.cvReseauPro;

    if (!url) {
      props.auth.toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Une erreur est survenue lors de la récupération du CV",
        life: 3000,
      });
      return;
    }

    if (!url.includes("recruiter")) url += "/recruiter";

    // Requête GET pour récupérer le CV (avec le token dans Authorization [Bearer])
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${props.auth.token}`,
        },
        responseType: "blob",
      });
      return new Blob([response.data], { type: "application/pdf" });
    } catch (error) {
      props.auth.toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: error.message || "Impossible de télécharger le CV",
        life: 3000,
      });
      return null;
    }
  };

  /**
   * Ouvre une nouvelle fenêtre pour télécharger le CV de l'utilisateur
   * @param {Blob|null} fileContent - Le contenu du fichier sous forme de Blob
   */
  const serveResume = async (fileContent) => {
    if (!fileContent) {
      return;
    }
    const url = URL.createObjectURL(fileContent);
    window.open(url);
    URL.revokeObjectURL(url); // Revoke the object URL to free up memory
  };

  useEffect(() => {
    if (!id || typeof id !== "string" || !id?.includes("-")) {
      displayErrorAndReturn(
        "Identifiant invalide, retour à la page précédente..."
      );
      return;
    }
    retrieveUserInformations(id);
    retrieveUserResume(id);
  }, [id]);

  return (
    <div className="profil_show">
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && userResume.loaded && (
            <ProfilBarShow
              user={{
                ...userResume.data,
                id: id.split("-")[1],
                profilTitle: userResume.data?.profileTitle,
                profilDescription: userResume.data?.profileDescription,
              }}
            />
          )}
        </div>
      </Header>
      <div className="profil_show__container">
        {props.auth.isConnected && (
          <>
            <div className="profil_show__container__column">
              <div className="profil_show__container__article">
                <div className="profil_show__container__article__title">
                  <h1 className="h1_trait_dessus">Formations et diplômes</h1>
                </div>
                <div className="profil_show__container__article__container">
                  {userData.loaded &&
                    userData.data &&
                    userData.data.educations
                      .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
                      ?.map((education) => (
                        <div
                          className="profil_show__container__article__container__experience"
                          key={education["education_id"]}
                        >
                          {education?.obtained_diplome_id &&
                            education?.certified === 1 && (
                              <img
                                src={crown}
                                style={{ height: "1rem" }}
                                alt={"Diplôme certifié"}
                              />
                            )}
                          <h4>
                            {education?.diploma ||
                              education["diplome_label"] ||
                              education["current_etape_label"]}
                          </h4>
                          <p>
                            {formatDate(education["start_date"], "/")} -{" "}
                            {formatDate(education["end_date"], "/")}{" "}
                          </p>
                          <div className="profil_show__container__article__container__experience__item">
                            {education?.current === 1 &&
                              education?.certified === 1 &&
                              "Diplôme certifié"}
                            {education?.current === 1 &&
                              education?.certified === 0 && <br />}
                            {education.school}
                            <br />
                            {education["domain_label"]}
                          </div>
                          <p>
                            <span>{education["study_level_label"]}</span>
                          </p>
                          <Divider />
                        </div>
                      ))}
                </div>
              </div>
              <div className="profil_show__container__article mobile">
                <div className="profil_show__container__article__title">
                  <h1 className="h1_trait_dessus">
                    Expériences professionnelles
                  </h1>
                </div>
                <div className="profil_show__container__article__container">
                  {userData.loaded &&
                    userData.data &&
                    userData.data.experiences
                      .sort(
                        (a, b) =>
                          new Date(b.endDate || Date.now()) -
                          new Date(a.endDate || Date.now())
                      )
                      .map((xp) => (
                        <div
                          className="profil_show__container__article__container__experience"
                          key={xp["experience_id"]}
                        >
                          <h4>
                            {xp.company} - {xp.city}
                          </h4>
                          <p>
                            {formatDate(xp["start_date"], "/")} -{" "}
                            {xp.current
                              ? "aujourd'hui"
                              : formatDate(xp["end_date"], "/")}
                          </p>
                          <div className="profil_show__container__article__container__experience__item">
                            <h5>
                              <strong>Poste occupé</strong> :{" "}
                            </h5>
                            <p>{xp.jobname}</p>
                          </div>
                          <div className="profil_show__container__article__container__experience__item">
                            <h5>
                              <strong>Secteur</strong> :{" "}
                            </h5>
                            <p>{xp["sector_label"]}</p>
                          </div>
                          <div className="profil_show__container__article__container__experience__item">
                            <h5>
                              <strong>Domaine</strong> :{" "}
                            </h5>
                            <p>{xp["domain_label"]}</p>
                          </div>
                          <div className="profil_show__container__article__container__experience__item">
                            <h5>
                              <strong>Contrat</strong> :{" "}
                            </h5>
                            <p>{xp["type_of_contract_label"]}</p>
                          </div>
                          {xp.description && (
                            <div className="profil_show__container__article__container__experience__item">
                              <h5>
                                <strong>Description</strong> :{" "}
                              </h5>
                              <p>{xp.description}</p>
                            </div>
                          )}
                          <Divider />
                        </div>
                      ))}
                </div>
              </div>
              <div className="profil_show__container__article">
                <div className="profil_show__container__article__title">
                  <h1 className="h1_trait_dessus">Compétences</h1>
                </div>
                <div className="profil_show__container__article__container__competences">
                  {userData.loaded &&
                    userData.data &&
                    userData.data.skills.map((item) => (
                      <div className="competence" key={item.id}>
                        <h4>{item["skill_label"]}</h4>
                      </div>
                    ))}
                </div>
              </div>
              <div className="profil_show__container__article desktop">
                <div className="profil_show__container__article__title">
                  <h1 className="h1_trait_dessus">Compétences</h1>
                </div>
                <div className="profil_show__container__article__container__competences">
                  {userData.loaded &&
                    userData.data &&
                    userData.data.skills.map((item) => (
                      <div className="competence" key={item.id}>
                        <h4>{item["skill_label"]}</h4>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {userData.loaded && userData.data && (
        <BtnBleu
          btnAction={async () => {
            const file = await retrieveDownloadableResume();
            file && serveResume(file);
          }}
        >
          Télécharger le CV Réseau Pro
        </BtnBleu>
      )}
      <Footer />
    </div>
  );
};

ProfilShow.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilShow);
