import PropTypes from "prop-types";
import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./ErrorBoundary.scss";
import BtnBleu from "../Boutons/BtnBleu/btn-bleu";

/**
 * ErrorBoundary is a component, acting at application level,
 * that catches non-caught errors and displays a fallback UI, by
 * showing an appropriate error message
 * @param children
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorDetails: null,
      defaultTitle: "Une erreur est survenue.",
      defaultMessage:
        "<p>Nous sommes navrés du désagrément.</p> <p>Veuillez réessayer ultérieurement.</p>",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error, errorDetails: error.stack };
  }

  componentDidCatch(error, errorInfo) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    this.setState({ error, errorInfo });
    if (process.env.NODE_ENV === "development") {
      console.error("ErrorBoundary caught an error", error, errorInfo);
    }
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <Header />
          <div
            style={{
              padding: "10svh 10svw",
            }}
          >
            <div className={"error-boundary"}>
              <h1 className={"error-boundary__title"}>
                {process.env.NODE_ENV === "development"
                  ? this.state.error.toString()
                  : this.state.defaultTitle}
              </h1>
              {process.env.NODE_ENV === "development" ? (
                <details
                  className={"error-boundary__details"}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </details>
              ) : (
                <div>
                  <p
                    className={"error-boundary__message"}
                    dangerouslySetInnerHTML={{
                      __html: this.state.defaultMessage,
                    }}
                  ></p>
                  <div className={"error-boundary__buttons"}>
                    <BtnBleu
                      btnTexte={"Retour à l'accueil"}
                      btnAction={() => (window.location.href = "/")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      );
    } else return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
