import ActualiteDetail from "Components/Actualités/ActualiteDetail/ActualiteDetail";
import CandidaturesByOffer from "Components/Candidatures/CandidaturesByOffer/CandidaturesByOffer";
import CandidaturesRecues from "Components/Candidatures/CandidaturesRecues/CandidaturesRecues";
import Error401 from "Components/Error/Error401/Error401";
import EvenementDetail from "Components/Evenements/EvenementDetail/EvenementDetail";
import Accessibilite from "Components/Footer/Accessibilite/Accessibilite";
import Aide from "Components/Footer/Aide/Aide";
import Contact from "Components/Footer/Contact/Contact";
import GestionCookies from "Components/Footer/GestionCookies/GestionCookies";
import MentionsLegales from "Components/Footer/MentionsLegales/MentionsLegales";
import PlanDuSite from "Components/Footer/PlanDuSite/PlanDuSite";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import OffreDetail from "Components/Offres/Offre/OffreDetail/OffreDetail";
import Actualites from "Pages/Actualites/Actualites";
import CVTheque from "Pages/CVTheque/CVTheque";
import Entreprises from "Pages/Entreprises/Entreprises";
import InformationsEntreprise from "Pages/InformationsEntreprise/InformationsEntreprise";
import ProfilShow from "Pages/Profil/ProfilShow/ProfilShow";
import Universites from "Pages/Universites/Universites";
import { UPDATE_AUTH } from "Redux/Actions/authActions";
import { useAxiosInterceptors } from "Services/Auth/authUtils";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Accueil from "../Accueil/Accueil";
import Evenements from "../Evenements/Evenements";
import Offres from "../Offres/Offres";
import Profil from "../Profil/Profil";
import "./app.scss";
import ErrorBoundary from "../../Components/ErrorBoundary/ErrorBoundary";

const App = () => {
  const interceptorTriggered = useAxiosInterceptors();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const updateAuth = (value) => {
    dispatch({ type: UPDATE_AUTH, value });
  };
  const toast = useRef(null);

  useEffect(() => {
    updateAuth({ toast: toast });
  }, [toast]);

  return (
    <div className="app">
      {interceptorTriggered && (
        <BrowserRouter>
          <Toast ref={toast} />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/evenements" element={<Evenements />} />
              <Route path="/actualites" element={<Actualites />} />
              <Route path="/universites" element={<Universites />} />
              <Route path="/espace-recruteurs" element={<Entreprises />} />
              <Route path="/offres" element={<Offres />} />
              {auth.isConnected && (
                <Route path="/profil" element={<Profil />} />
              )}
              <Route path="/profil-show/:id" element={<ProfilShow />} />
              <Route path={"/offre-detail/:id"} element={<OffreDetail />} />
              <Route path={"/event-detail/:id"} element={<EvenementDetail />} />
              <Route path={"/actu-detail/:id"} element={<ActualiteDetail />} />
              <Route path="/cvtheque" element={<CVTheque />} />
              <Route
                path="/entreprise-edit/:id"
                element={<InformationsEntreprise />}
              />
              <Route path="/universites" element={<Universites />} />
              <Route path="/error401" element={<Error401 />} />
              <Route
                path="/candidatures-profil/:id"
                element={<CandidaturesByOffer />}
              />
              <Route path="/offres" element={<CandidaturesRecues />} />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
              <Route path="*" element={<Accueil />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/aide" element={<Aide />} />
              <Route path="/accessibilite" element={<Accessibilite />} />
              <Route path="/gestion-cookie" element={<GestionCookies />} />
              <Route path="/plan-site" element={<PlanDuSite />} />
            </Routes>
          </ErrorBoundary>
          <ModalCharte />
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
